import React from 'react'

export default function Head() {
return (
    <>
        <section className='head'>
            <div className='container flexSB'>
                <div className='logo'>
                    <h1>E-SIGNATURE</h1>
                    <span>SIGN DOCUMENTS ONLINE</span>
                </div>
             
            
            </div>
        </section>
    </>
)
}