import React from 'react'
import "./about.css"
import Back from "../common/back/Back"
import AboutCard from "./AboutCard"

export default function About() {
return (
    <>
        <Back title='About Us' />
        <AboutCard />
    </>
)
}