import "./App.css"
import Header from "./components/common/header/Header"
import { BrowserRouter , Routes, Route } from "react-router-dom"
import Home from "./components/home/Home"
import Home1 from "./components/home/Home1"
import About from "./components/about/About"
import About1 from "./components/about/About1"
import Team from "./components/team/Team"
import Team1 from "./components/team/Team1"
import Footer from "./components/common/footer/Footer"
import Header1 from "./components/common/header/Header1"
import Footer1 from "./components/common/footer/Footer1"

import LoginPage from "./components/login/LoginPage"
import Table from "./components/table/Table"
import Step1 from "./components/steps/Step1"
import Step2 from "./components/steps/Step2"
import Step3 from "./components/steps/Step3"
import SignDocument from "./components/sign/SignDocument";
import Keys from "./components/secret/Keys"
import RequireAuth from './components/auth/RequireAuth'; // Import your RequireAuth HOC
import Unauthorized from "./components/unauthorized/Unauthorized"
import ProtectedRoute from './components/auth/ProtectedRoute';
const ROLES = {
  'User': 2001,
  'Editor': 1984,
  'Admin': 5150
}

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" />
      <Route index element={<><Header/><Home/><Footer/></>} />
      <Route path="about"  element={<><Header/><About/><Footer/></>} />
            <Route path="about1"  element={<><Header1/><About1/><Footer1/></>} />

      <Route path="home1"  element={<><Header1/><Home1/><Footer1/></>} />

      <Route path="team"  element={<><Header/><Team /><Footer/></>} />
       <Route path="team1"  element={<><Header1/><Team1 /><Footer1/></>} />
      <Route path="SignDocument/:id/:signer"  element={<SignDocument />} />
      <Route path="keys"  element={<Keys />} /> 
      <Route path="unauthorized" element={<Unauthorized />} />

      <Route path="login"  element={<LoginPage />} /> 
      <Route path="table" element={<ProtectedRoute />}>
          <Route index element={<Table />} />
        </Route>
      <Route path="step1"  element={<Step1 />} />
      <Route path="Step2/:id"  element={<Step2 />} />
      <Route path="Step3/:id"  element={<Step3 />} />
      <Route path="*"  element={<LoginPage />} />
    </Routes>
  </BrowserRouter>
    
  );
}

export default App;