import React, {useState, useEffect } from 'react';
import axios from 'axios';
import './keys.css';
import {FaKey} from "react-icons/fa"

import {FaUnlock} from "react-icons/fa"

import {FaRedoAlt} from "react-icons/fa"
function Keys() {
    
    return (
        <div className='main-table'>
            <header >
                            <div className=''>

               <h3 className="styled-title1">API KEYS</h3>
                               </div>


            </header>
            <div className='container mt-12'>
                <br /><br /><br /><br /><br />


  <p>  <FaKey />    {'  '}       
 KEY : </p>
                  <input style={{width : '50%'}} type="text" placeholder=""/>  
                   <p> <FaUnlock />    {'  '}       Secret : </p>

              <input style={{width : '50%'}} type="text" placeholder=""/>    
                <div>
             
                    <p> <FaRedoAlt />    {'  '} Generate NEW API KEY : </p>
                </div>
                 
       
                <button type="button" style={{float: 'left',backgroundColor : '#4dd6b6'}} className="btn " > generate new key </button>
                <br />
                <div className=''>

                         
                
                </div>
                <br />
                {/* -------------------------------------------------------------------- */}
          





             { /* -------------------------------------------------------------------- */}
                <br /><br /><br />
            </div>
        </div>
    )
}
export default Keys