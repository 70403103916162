import React, { useCallback, useState } from 'react'
import { Container } from './Container.js'
 const Example =  React.forwardRef((props,ref) => {
  const [hideSourceOnDrag, setHideSourceOnDrag] = useState(true)
  
  return (
    <>
      <Container title={props.title} closeSection={props.closeSection} getUpdateSection={props.getUpdateSection} uri={props.uri} pageIdx={props.pageIdx} image={props.image} ref={ref} hideSourceOnDrag={hideSourceOnDrag} />
     
    </>
  )
})
export default Example;
