import './App.css';
import React,{useEffect, useRef,useState} from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import SignatureCanvas from "react-signature-canvas";
import {  useParams } from 'react-router-dom';
import axios from "axios";
import {  PDFDocument,setFillingRgbColor,StandardFonts  } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';

function SignDocument() {
  const inputRefs = useRef([]);
  const { id,signer } = useParams();
  const [InfoPdf, setInfoPdf] = React.useState([]);
  const [urlPdf, setUrlPdf] = React.useState("");
  const [urlTemplate, setUrlTemplate] = React.useState("");
    const [pdf, setPdf] = React.useState("");
    const [width, setWidth] = React.useState(0);
    const [images, setImages] = React.useState([]);
    const [height, setHeight] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pdfRendering, setPdfRendering] = React.useState("");
    const [pageRendering, setPageRendering] = React.useState("");
    const [valueInputs, setValueInputs] = useState([])
   const functiontest = () =>{
    //alert("ok");
    //window.parent.postMessage("submitClicked", "*");
    function validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
    var test = 0;
    //console.log(valueInputs);
    //let test = InfoPdf.filter(input => input.obligatoire == 1)
    //console.log('rrr',InfoPdf);
    InfoPdf.map((value,idx)=>{
     
      const index = valueInputs.findIndex(input => {
        if(input){
        //console.log(input);
        //console.log(value);
       return input.id === value.id
      }else{return false}
      });
      if(index == -1 && value.obligatoire==1){
       // alert("required")
        inputRefs.current[idx].scrollIntoView({ behavior: "smooth", block: "center" });
        inputRefs.current[idx].style.borderColor = 'red';
        test = -1;
      }
      if(index != -1 && value.obligatoire==1){
        if(valueInputs[index].input_type != "signature" && valueInputs[index].text==""){
          inputRefs.current[idx].scrollIntoView({ behavior: "smooth", block: "center" });
          inputRefs.current[idx].style.borderColor = 'red';
          test = -1;
        }

      }
      if(index != -1 ){
        if(valueInputs[index].input_type == "email" && (valueInputs[index].text!="" && !validateEmail(valueInputs[index].text))){
          
          inputRefs.current[idx].scrollIntoView({ behavior: "smooth", block: "center" });
          inputRefs.current[idx].style.borderColor = 'red';
          test = -1;
        }

      }
      
    })
    if( test == 0){
      functiontest2();
    }
    ///console.log(InfoPdf);
    //console.log(valueInputs)
   
   }
    const functiontest2  = async () =>{
     
      const existingPdfBytes = await fetch(
        urlTemplate
      ).then((res) => res.arrayBuffer());
      const pdfDoc =  await PDFDocument.load(existingPdfBytes);
      const form = pdfDoc.getForm()
      
      console.log('rrrrrtyu',form.getFields()[0].getName())
      pdfDoc.registerFontkit(fontkit)
      const NameFields = [];
      
      const results = await Promise.all(valueInputs.map( async (test,idx)=>{
      
        if(test.input_type=="signature"){  
          const characterImageField = form.getButton(test.name_in_pdf)
          NameFields.push(test.name_in_pdf);
          const marioUrl = test.img ;
          const marioImageBytes = await fetch(marioUrl).then(res => res.arrayBuffer())
          
          const marioImage = await pdfDoc.embedPng(marioImageBytes)
          characterImageField.setImage(marioImage)
          characterImageField.enableReadOnly();
          
        }else{
          const helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica)
          const nameField  = form.getTextField(test.name_in_pdf)
          NameFields.push(test.name_in_pdf);
          //const fieldDimensions = nameField.getDimensions();
          console.log(nameField)
          //nameField.drawText('Select your favorite planet*:', { x: 50, y: 280, size: 20 })
          nameField.defaultUpdateAppearances(helvetica)

          
          nameField.setText(test.text)
          nameField.setFontSize(11);

          nameField.enableReadOnly();
        }
      }));
      
      
     
    const pdfBytes = await pdfDoc.save();


    const bytes  = new Uint8Array( pdfBytes ); 
      const blob   = new Blob( [ bytes ], { type: "application/pdf" } );
  const docUrl = URL.createObjectURL( blob );
  let formData = new FormData();
  formData.append('signer', signer);
  let fileName = `test.pdf`;
  let file = new File([blob], fileName);
  formData.append('file', file, fileName);
  axios.post(`https://test.sfaxcharityerpapp.com/api/saveNewTemplate/${id}`,formData,{
    headers: {
    "Content-type": "multipart/form-date",
  },
  })
  .then((result) => {
    console.log("result",result);
  })
  .catch((res) => {

    
  });


  /* save as new temlatee */
  form.getFields().map( (value,idx)=>{
    if(!(NameFields.includes(value.getName())) && !(value.isReadOnly())){
      form.removeField(value);

    }

  });
  form.flatten();
  const pdfBytes2 = await pdfDoc.save();


  const bytes2  = new Uint8Array( pdfBytes2 ); 
    const blob2   = new Blob( [ bytes2 ], { type: "application/pdf" } );
const docUrl2 = URL.createObjectURL( blob2 );
      let formData2 = new FormData();
      formData2.append('signer', signer);
      let fileName2 = `test.pdf`;
      let file2 = new File([blob2], fileName2);
      formData2.append('file', file2, fileName2);
      axios.post(`https://test.sfaxcharityerpapp.com/api/signedDocument/${id}`,formData2,{
        headers: {
        "Content-type": "multipart/form-date",
      },
      })
      .then((result) => {
        console.log("result",result);
        window.parent.postMessage("submitClicked", "*");
      })
      .catch((res) => {
   
        
      });
                /* let alink = document.createElement('a');
                alink.href = docUrl;
                alink.download = 'SamplePDF.pdf';
                alink.click(); */
               // window.parent.postMessage("submitClicked", "*");
     }

    const signFunction = (img,page,input) => {
      
      var test = InfoPdf[input];
      test.img = img;
      var valInputs =valueInputs;
      valInputs[input] = test;
      setValueInputs(valInputs);


    }
    const changeText = (t,page,input) => {
    
      var test = InfoPdf[input];
      test.text = t.target.value;
      var valInputs =valueInputs;
      valInputs[input] = test;
      setValueInputs(valInputs);



    }
    

  useEffect(() => {
    axios.get(`https://test.sfaxcharityerpapp.com/api/getDocument4/${id}`, { responseType: 'blob' })
      .then((res) => {
         //alert('ddd')
         //console.log(res.data)
         setUrlPdf("");
          let test = URL.createObjectURL(res.data);
          //console.log(test)
          setUrlPdf(test);
         
          //showPdf();
      
      })
      .catch((res) => {
   
        
      });
      axios.get(`https://test.sfaxcharityerpapp.com/api/getTemplate4/${id}`, { responseType: 'blob' })
      .then((res) => {
         //alert("ok");
          let test = URL.createObjectURL(res.data);
          setUrlTemplate(test);
         
          //showPdf();
      
      })
      .catch((res) => {
   
        
      });
      axios.get(`https://test.sfaxcharityerpapp.com/api/getDocInfo/${id}/${signer} `)
      .then((res) => {
        
          setInfoPdf(res.data);
      
      })
      .catch((res) => {
   
        
      });
  }, []);
  useEffect(() => {
    if(urlPdf){
      showPdf();
    }
    
  }, [urlPdf]);

    const [uri, SetUri] = React.useState();
  
  const styles = {
    wrapper: {
      width: '100%',
      margin: '10px 0px',
      paddingTop: '129.412%',
      position:'relative'
      
      
    },
    imageWrapper: {
      // width: "300px",
      // height: "350px",
      border: "1px solid rgba(0,0,0,0.15)",
      borderRadius: "3px",
      boxShadow: "0 2px 5px 0 rgba(0,0,0,0.25)",
      padding: "0",
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 'auto',
    }
  };
 
  async function showPdf(event) {
    

    try {
      setPdfRendering(true);
     
      const uri = urlPdf;  
      SetUri(uri);
      var _PDF_DOC = await pdfjsLib.getDocument({ url: uri }).promise;
      const page = await _PDF_DOC.getPage(1);
			const viewport = page.getViewport({ scale: 1.5 });
      
      setPdf(_PDF_DOC);
      setPdfRendering(false);
      document.getElementById("file-to-upload").value = "";
    } catch (error) {
      alert(error.message);
    }
  }
  async function renderPage() {
    setPageRendering(true);
    const imagesList = [];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("className", "canv");
    let canv = document.querySelector(".canv");

    for (let i = 1; i <= pdf.numPages; i++) {
      var page = await pdf.getPage(i);
      var viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport
      };
      setWidth(viewport.width);
      setHeight(viewport.height);
      await page.render(render_context).promise;
      let img = canvas.toDataURL("image/png");
      imagesList.push(img);
    }
    setImages(imagesList);
    setPageRendering(false);
  }

  useEffect(() => {
    pdf && renderPage();
    // eslint-disable-next-line
  }, [pdf, currentPage]);

  return (
    <>
   
    <div className="container-fluid mt-5">
      <div className='row'>
        <div className='col-12 '>
         <div className=''style={{
                textAlign: "-webkit-center"
                }}> 
         <button className="btn btn-success btn-lg  float-end"
        id="upload-button"
        onClick={() => functiontest()}
      >
        Submit
      </button>
      <input
        type="file"
        id="file-to-upload"
        accept="application/pdf"
        hidden
        onChange={showPdf}
      />
      

        </div>
        </div>
         <div className='col-12 ' 
            style={{
                overflow: "auto",
                backgroundColor: '#f8f9fa'
            }}>
          
        

            <div className="App mt-5 mb-5" style={{
                textAlign: "-webkit-center"
                }}>
           <div id="pdf-contents">
      
          <div id="image-convas-row" style={{
                 position: 'relative',
                 width: '100%',
                 overflow: 'auto',
           
              
            }}>
            <div style={{
                  //width: '',
            }}>
              {images.map((image, idx) => (
                 <div style={styles.wrapper}>
                <div key={idx} style={styles.imageWrapper}>
                    <div  >
            <img src={image} style={{maxWidth: '100%',width: '100%', height: 'auto', position: 'relative',
      display: 'block',}}></img>
            </div>
    {
      InfoPdf.map((btn, idxbtn) => {
        if (btn.page === idx) {
          return(
            <MyInput ref={(el) => (inputRefs.current[idxbtn] = el)}  key={idxbtn} type={btn.input_type} height={btn.height} width={btn.width} input={idxbtn} page={idx} x_img={btn.x_img} y_img={btn.y_img} onSign={(e,k,f)=>signFunction(e,k,f)} changeText={(t,k,f)=>changeText(t,k,f)} />

          )
        }
      }
      
      
     )
    }

 
</div>
                </div>
              ))}
            </div>
          </div>
          <div id="page-loader" hidden={!pageRendering}>
            Loading page ...
          </div>
         {/*  <button className="btn btn-primary btn-lg mt-5">Show PNG</button>
          <button>Download PNG</button> */}
        </div>
            </div>
  

        </div>
        <div className='col-2' 
            style={{
                overflow: "auto"
            }}>
    

  

        </div>
      </div>
      
    </div>
    
   
    </>
  );
}

export default SignDocument;
const MyInput = React.forwardRef((props, ref) => {
  const [openModel, setOpenModal] = useState(false);
 
    const sigCanvas = useRef()
    const [imageURL, setImageURL] = useState(null);
  const create = () => {
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    setImageURL(URL);
    setOpenModal(false);
    props.onSign(URL,props.page,props.input);
  };
  if(props.type!="signature"){
    return (
      <div style={{
        position: "absolute",
       
        top: `${props.y_img}%`,
        left: `${props.x_img}%`,
        zIndex: 1,
      //transform: 'translate(-50%, -50%)',
        width:  `${props.width}%`, //maxWidth: '20%'
        height: `${props.height}%`,
      }}>
        
      <input ref={ref} onChange={(e)=>props.changeText(e,props.page,props.input)} style={{
        margin: '0px!important',
        padding: '0px!important',
        width: '-webkit-fill-available',
        height: '-webkit-fill-available',
        overflow: 'hidden',
        /* font-size: 100%; */
        verticalAlign: 'top',
      }} type="text" className=""/> </div>
   )
  }
else if(!imageURL){
return(

<>

<div style={{
  position: "absolute",
 
  top: `${props.y_img}%`,
  left: `${props.x_img}%`,
  zIndex: 1,
//transform: 'translate(-50%, -50%)',
width:  `${props.width}%`, //maxWidth: '20%'
height: `${props.height}%`,
}}>
<button ref={ref} style={{
  margin: '0px!important',
  padding: '0px!important',
  width: '-webkit-fill-available',
  height: '-webkit-fill-available',
  overflow: 'hidden',
  /* font-size: 100%; */
  verticalAlign: 'top',
}}  onClick={()=>{
  //alert()
  setOpenModal(true);
}}  className="">Sign</button> </div>

{openModel && (
        
  <div className="modalContainer">
    <div className="modala">
      <div className="modal__bottom">
      <div className="">
  <SignatureCanvas
  ref={sigCanvas}
  onEnd={(e)=>{
  }}
    penColor="blue"
    canvasProps={{ width: 500, height: 200 }}
  />
</div>
<button className="create" onClick={create}>
Create
</button>
        <button onClick={() => setOpenModal(false)}>Cancel</button>
      </div>
    </div>
  </div>
)}</>)
}
  return ( <>
  
  
  <img  style={{
  position: "absolute",
  top: `${props.y_img}%`,
  left: `${props.x_img}%`,
  zIndex: 1,
//transform: 'translate(-50%, -50%)',
width:  `${props.width}%`, //maxWidth: '20%'
height: `${props.height}%`,
}} onClick={()=>{
  setOpenModal(true);
}} src={imageURL} alt="signature" className="signature" /> {openModel && (
        
  <div className="modalContainer">
    <div className="modala">
      <div className="modal__bottom">
      <div className="">
  <SignatureCanvas
  ref={sigCanvas}
  onEnd={(e)=>{
  }}
    penColor="blue"
    canvasProps={{ width: 500, height: 200 }}
  />
</div>
<button className="create" onClick={create}>
Create
</button>
        <button onClick={() => setOpenModal(false)}>Cancel</button>
      </div>
    </div>
  </div>
)}


</>
  
)
})