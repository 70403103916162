import update from 'immutability-helper'
import React,{ useCallback, useState,useImperativeHandle,useEffect,useRef } from 'react'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {Rnd} from "react-rnd";
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import Modal from './Modal.js';

export const Container =  React.forwardRef((props, ref) => {
  const [uri, SetUri] = React.useState();
  const inputEl  = useRef(null);
  const [position, setPosition] = useState({ x: 50, y: 50 });
  const [percentage, setPercentage] = useState(1);
  const [imageWidth, setImageWidth] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [startWidth, setStartWidth] = useState(200);
  const [startHeight, setStartHeight] = useState(200);
  const [isOpen, setIsOpen] = React.useState(false);
  const handleClick = (value,index) =>{
    props.getUpdateSection(value,index);
          
              const updatedList = boxes.map(item => {
                if (!item.isActive) {
                  return item; // do nothing, property already true
                } else {
                  return {...item, isActive: false}; // create a new object with updated property
                }
              });
              //setBoxes(updatedList); // update the state with the new list
            
            const newboxes = [...updatedList]; // create a copy of the original list
            newboxes[index] = {...newboxes[index],isActive:true}; // update the object at index i with newObject
            setBoxes(newboxes);
  }
  const openModal = () => {
    //alert( )
    setIsOpen(true);
}
const closeModal = () => {
  //alert( )
  setIsOpen(false);
}
  const handleDrag = (index, _, { x, y },width,height) => {
    const otherPositions = boxes.filter((_, i) => i !== index);

    const overlaps = otherPositions.some(({ left: otherX, top: otherY,width:widthOther,height:heightOther }) => {
      
      const horizontalOverlap = x < otherX + ((widthOther*inputEl.current.offsetWidth)/100) && x + ((width*inputEl.current.offsetWidth)/100) > otherX;
      
      const verticalOverlap = y < otherY +  ((heightOther*inputEl.current.offsetHeight)/100) && y + ((height*inputEl.current.offsetHeight)/100) > otherY;
      return horizontalOverlap && verticalOverlap ;
    });
    console.log(overlaps);

    return overlaps
  
  };
  const validationResize = (index, _, ref,delta, { x,y,size},width,height) => {
    const horizontalOverlap1 = x + ((width*inputEl.current.offsetWidth)/100) + delta.width > inputEl.current.offsetWidth;
    const verticalOverlap1 = y+((height*inputEl.current.offsetHeight)/100) + delta.height > inputEl.current.offsetHeight;
    
    const otherPositions = boxes.filter((_, i) => i !== index);

    const overlaps = otherPositions.some(({ left: otherX, top : otherY,width:widthOther,height:heightOther }) => {
      const horizontalOverlap = x < otherX + ((widthOther*inputEl.current.offsetWidth)/100) && x + ((width*inputEl.current.offsetWidth)/100) + delta.width > otherX;
//console.log(x,otherX,((widthOther*inputEl.current.offsetWidth)/100),((width*inputEl.current.offsetWidth)/100),delta.width);
      const verticalOverlap = y < otherY + ((heightOther*inputEl.current.offsetHeight)/100) && y + ((height*inputEl.current.offsetHeight)/100) + delta.height > otherY;
      return horizontalOverlap && verticalOverlap ;
    });
    console.log(horizontalOverlap1,verticalOverlap1)
    console.log(overlaps || horizontalOverlap1 || verticalOverlap1)
    return overlaps || horizontalOverlap1 || verticalOverlap1 ;
   
  };
  


  const handleMouseDown = (event) => {
    event.preventDefault();
    if (event.target.classList.contains('box-drag-handle')) {
      setIsDragging(true);
      setStartX(event.clientX);
      setStartY(event.clientY);
    } else if (event.target.classList.contains('box-resize-handle')) {
      setIsResizing(true);
      setStartX(event.clientX);
      setStartY(event.clientY);
      setStartWidth(event.target.parentElement.offsetWidth);
      setStartHeight(event.target.parentElement.offsetHeight);
    }
  };

  const handleMouseMove = (event) => {
    event.preventDefault();
    if (isDragging) {
      const newLeft = startWidth + event.clientX - startX;
      const newTop = startHeight + event.clientY - startY;
      const box = document.getElementById('box');
      box.style.left = `${newLeft}px`;
      box.style.top = `${newTop}px`;
    } else if (isResizing) {
      const newWidth = startWidth + event.clientX - startX;
      const newHeight = startHeight + event.clientY - startY;
      const box = document.getElementById('box');
      box.style.width = `${newWidth}px`;
      box.style.height = `${newHeight}px`;
    }
  };

  const handleMouseUp = (event) => {
    event.preventDefault();
    setIsDragging(false);
    setIsResizing(false);
  };


  function handleImageResize(event) {
  
    const width = event.target.width;
    //alert(width)
    setImageWidth(width);
  }
  useEffect(() => {
    const handleResize = () => {
      console.log("Screen was resized!");
      // Call your function here
      console.log( inputEl.current.offsetWidth)
      console.log( imageWidth)
      setPercentage((inputEl.current.offsetWidth/imageWidth))
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [imageWidth]);
  const navigate = useNavigate();
  const style2 = {
    position: 'inherit',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    overflow: 'hidden',
  };

  const styles = {
    wrapper: {
      /* width: '100%', */
/*       margin: '10px 0px',
 */      /* paddingTop: '129.412%', */
      position:'relative',
      height: '100%',
      margin:'2%'
      
      
    },
    imageWrapper: {
      // width: "300px",
      // height: "350px",
      border: "1px solid rgba(0,0,0,0.15)",
      borderRadius: "3px",
      boxShadow: "0 2px 5px 0 rgba(0,0,0,0.25)",
      padding: "0",
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '-webkit-fill-available',
    }
  };
    const [boxes, setBoxes] = useState([])
    const [boxesPage, setBoxesPage] = useState([])
    const [page, SetPage] = React.useState(0);

    const [pdfInfo, setPdfInfo] = useState([]);
    const [pdf, setPdf] = useState(false);
    useEffect(() => {
      var test = boxesPage ; 
      test[page]=boxes
    
      setBoxesPage(test)
      if(test[props.pageIdx]){
        setBoxes(test[props.pageIdx]);
      }else{
      setBoxes([]);
    }
    SetPage(props.pageIdx)
      
    }, [props.image]);
    
    useEffect(() => {
      //alert(page)
      SetUri(props.uri)
      // eslint-disable-next-line
    }, [props.uri]);
  
    const modifyPdf = async (h,w,id,Nw,Ny) => {
      const existingPdfBytes = await fetch(
        uri
      ).then((res) => res.arrayBuffer());
  
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
  
      const pages = pdfDoc.getPages();
      const form = pdfDoc.getForm()
      boxesPage.map((valueP,indexP) => {
        const firstPage = pages[indexP];
      const { width , height } = firstPage.getSize();
      valueP.map((value,index) => {
        const { left, top,  title,type,Obligatoire } = value
        const mywidth = value.width;
        const myheight = value.height;
        if(type=="signature"){
          const button = form.createButton(title+index+indexP)
          console.log(mywidth,myheight)
          button.addToPage(title, firstPage, {
            x: ((width/w )*(left)),
            y: ((height/h)*(h-top-(myheight*h/100) )),
            width: ((width/w )*mywidth*(w/100)),
            height: ((height/h)*myheight*(h/100)),
            textColor: rgb(1, 0, 0),
            backgroundColor: rgb(0, 1, 0),
            borderColor: rgb(0, 0, 1),
            borderWidth: 2,
          })
        }else{
          const superheroField = form.createTextField(title+index+indexP)
          superheroField.addToPage(firstPage, { 
            x: ((width/w )*(left)),
            y: ((height/h)*(h-top-(myheight*h/100) )),
            width: ((width/w )*mywidth*(w/100)),
            height: ((height/h)*myheight*(h/100)),
          borderColor: rgb(1, 1, 1),backgroundColor: rgb(1, 1, 1) , opacity: -9999} )
        }
      
    })
  })
      // Get the width and height of the first page
   
  
      const pdfBytes = await pdfDoc.save();
      const bytes  = new Uint8Array( pdfBytes ); 
const blob   = new Blob( [ bytes ], { type: "application/pdf" } );
const docUrl = URL.createObjectURL( blob );
     
      setPdfInfo(docUrl);
  
      
      //let blob = blob ;
      const data = JSON.stringify({inputs: boxesPage})
      console.log('boxesPage',boxesPage)
      let formData = new FormData();
      formData.append('boxesPage', data);
      formData.append('title', props.title);
      let fileName = `achraf.pdf`;
      let file = new File([blob], fileName);
      formData.append('file', file, fileName);
      axios.post(`https://test.sfaxcharityerpapp.com/api/CreateTemplates/${id}`,formData,{
        headers: {
        "Content-type": "multipart/form-date",
      },
      })
      .then((result) => {
        console.log("result",result);
      })
      .catch((res) => {
   
        
      });

    };
 
  const moveBox = useCallback(
    (id, left, top, width ,height) => {
      //alert(left);
      setBoxes(
        update(boxes, {
          [id]: {
            $merge: { left, top,width,height },
          },
        }),
      )
      
    },
    [boxes, setBoxes],
  )
  useImperativeHandle(ref, () => {
    return {
      DeleteBox:(idx)=> {
        //alert(idx)
        const newItems = boxes.filter((item,index) => index !== idx);
        setBoxes(newItems);
      },
      updateSignature:(idx,titleName,type,Obligatoire,role)=> {
        //alert(idx);
        setBoxes(boxes.map((boxe,index) => {
          if (index === idx) {
            return { ...boxe, title: titleName,type:type,Obligatoire:Obligatoire,role:role };
          } else {
            return boxe;
          }
        }));
     console.log(boxes)
      },
      closeUpdateSection : () => {
        const updatedList = boxes.map(item => {
          if (!item.isActive) {
            return item; // do nothing, property already true
          } else {
            return {...item, isActive: false}; // create a new object with updated property
          }
        });
        setBoxes(updatedList); 
      },
         addSignature : (e,type,Obligatoire,role,color) => {
          const updatedItems = boxes.map(item => ({
            ...item,
            isActive: false
          }));
          //setBoxes(updatedItems);
         
          //console.log('hethi',updatedItems);
             setBoxes([...updatedItems,
                { top: 20, left: 0,width:20,height:10, title: e, type:type,Obligatoire:Obligatoire, role:role,color:color,widthImg:inputEl.current.offsetWidth ,heightImg:inputEl.current.offsetHeight,isActive:true },
            ]) 
          },
          createPdf: (id) => {
           // alert(id)
            var test = boxesPage ; 
            test[page]=boxes
          
            setBoxesPage(test)
            setPdf(true);
            console.log(inputEl.current.offsetHeight ,inputEl.current.offsetWidth,inputEl.current.naturalWidth )
            modifyPdf(inputEl.current.offsetHeight ,inputEl.current.offsetWidth,id,inputEl.current.naturalWidth,inputEl.current.naturalHeight);
          //navigate("/")
         }
    };
  }, [boxes]);

  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#f0f0f0"
  };
  return (
    
    <>{pdf ? <iframe title="test-frame" height={500} width={500} src={pdfInfo} type="application/pdf" /> :   
    
    <div style={styles.wrapper} className={1} >
  
  <Modal isOpen={isOpen} onClose={closeModal}>
  <div className="card">
              <div className="card-body">
                <h5 className="card-title">Update field</h5>
                <div className="mb-3">
                  <label  className="form-label">Signatories</label>
                  <select  className="form-select" >
                    
                    
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">Field name</label>
                  <input type="email"  className="form-control"  aria-describedby="emailHelp"/>
                </div>
                <div className="mb-3">
                  <label  className="form-label">Field type</label>
                  <select  className="form-select" >
                    <option value="signature">signature</option>
                    <option value="name">name</option>
                    <option value="email">email</option>
                  </select>
                </div>
                <div className="mb-3 form-check">
                  <input type="checkbox" id='ttest' className="form-check-input" value="ture"  />
                  <label className="form-check-label" htmlFor='ttest'>Obligatoire</label>
                </div>
                <div className="mb-3">
                <a href={"#"} style={{color:'red'}}>Delete this filed</a>
                </div>
                <button type="button" className="btn btn-primary btn-lg mt-5">Save</button>

              </div> 
              </div></Modal>
            <img ref={inputEl} onClick={()=>{
               const updatedList = boxes.map(item => {
                if (!item.isActive) {
                  return item; // do nothing, property already true
                } else {
                  return {...item, isActive: false}; // create a new object with updated property
                }
              });
              setBoxes(updatedList); 
              props.closeSection();
            }} draggable={false}  onLoad={handleImageResize}  src={props.image}  style={{maxWidth: '100%',width: '100%', height: 'auto', position: 'relative',
      display: 'block',}}></img>
       
          
    {boxes.map((value,index) => {
      console.log('deddddd',value)
        const { left, top,width,height, title ,type,role,color,isActive } = value
        //alert(color)
        return (
         
           <Rnd  className={` ${isActive ? 'pattern' : ''}`}
           onClick={()=>handleClick(value,index)}
           onStart={()=>handleClick(value,index)}
          style={{...style, background: color}}
          //style={style}
          
          size={{ width: `${width}%`, height: `${height}%` }}
          position={{
            x: left*percentage,
            y: top*percentage,
          }}
          //position={position}
          onResizeStop={(e, direction, ref, delta, position) => {
            console.log(delta);
            const validation = validationResize(index, e, ref,delta, position,width,height);
            console.log('okkkkk',validation)
            if(validation){
              //ref.node.style.transform = "none";
          }else{
            moveBox(index, left, top,parseInt(ref.style.width,10),parseInt(ref.style.height,10));
            }

          }}
          onDragStop={(e, d) => { 
            
            const test = handleDrag(index, e, d,width,height);
            //console.log(width );
            if (d.x < 0 || d.y < 0 || d.x + ((width*inputEl.current.offsetWidth)/100) > inputEl.current.offsetWidth || d.y + ((height*inputEl.current.offsetHeight)/100) > inputEl.current.offsetHeight || test) {
              d.node.style.transform = "none";
            }else{
              moveBox(index, d.x, d.y,width,height);
            }
            

           }}
        >
          <div>{title}</div>
        </Rnd>
        /*  </div> */
         
          

        )
      })}
    </div>}
    
      </>
  );
 
})
