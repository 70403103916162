import React,{useEffect, useRef,useState,createRef} from 'react';
import { Stepper } from 'react-form-stepper'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import {  useParams } from 'react-router-dom';
import './step2.css';

function Step2() {
    const [isSticky, setIsSticky] = useState(false);
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
  };

  useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => {
          window.removeEventListener("scroll", handleScroll);
      };
  }, []);
    const { id } = useParams();

    const navigate = useNavigate();
    const inputElement = useRef();
    const fileTypes = ["pdf"];
    const [file, setFile] = useState(null);
    const [signers, setSigners] = useState([{name:"Admin",email:'test@test.com',color:'#e2e2e2'},]);
    const addSigner = () => {
      function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#e2e2';
        for (var i = 0; i < 2; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }
      let mycolor = getRandomColor();
      setSigners(signers => [...signers, {name:"",email:"",color:mycolor}]);
    };
    const removeSigners = (idx) => {
      //alert(idx)
      let data = [...signers];
    data.splice(idx, 1)
    setSigners(data)
     
    };
    const updateSigners = (e,idx,type) => {
      let data = [...signers];
      data[idx][e.target.name] = e.target.value;
      setSigners(data);

      
     
    };
  const handleChange = (file) => {
    setFile(file);
  };
  const handleNext = () => {
    //const data = JSON.stringify({inputs: boxesPage})
    let formData = new FormData();
    formData.append('signers', JSON.stringify(signers));
   
    axios.post(`https://test.sfaxcharityerpapp.com/api/SaveSigners/${id}`,formData,{
        headers: {
        "Content-type": "multipart/form-date",
      },
      })
      .then((result) => {
        console.log("result",result);
        navigate(`/Step3/${id}`)
      })
      .catch((res) => {
   
        
      });
    //navigate(`/Step3/${id}`)
   
  };

    return (
    <>
        <div className='main-table'>
            <header className={isSticky ? 'sticky' : ''}>
            <a href="/" className="logo">Home Page</a>
            <ul>
                <li><a href="/table">Templates</a></li>
                <li><a href="#">Signature</a></li>
                <li><a href="#">UserMenu</a></li>
            </ul>
            </header>
            <div className='container mt-5'>
            <br/><br/><br/><br/>
            <div>
                <h1 className="styled-title1">Create</h1>
                <h1 className="styled-title">TEMPLATE...</h1>
            </div>
            <div className="container-fluid" style={{ height: 'auto'}}>
                <div className='row'>
                    <div className='col-12'>
                        <Stepper
                            steps={[
                            { label: "Step 1" },
                            { label: "Step 2" },
                            { label: "Step 3" },
                            ]}
                            currentStep={2}
                        />
                    </div>
                    <div className='col-12 align-items-center justify-content-center'>
                    <div className=''>
                    <div><h3>Add Roles</h3></div>
                    {signers.map((value, idx) => (
                            <div className=" border border-1 p-3 mb-2" key={idx}>
                                <div className="col-12">
                                <button type="button" onClick={()=>{removeSigners(idx)}} className="btn-close float-end" aria-label="Close" />
                    </div>
                    <div className="col-12">
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">Role name </label>
                        <input type="text" name='name' className="form-control" value={value.name} onChange={(e)=>{updateSigners(e,idx,'name')}} id="exampleInputEmail1" aria-describedby="emailHelp"/>
                    </div>
                    </div>
           
          </div>
            ))}
          <button type="button" onClick={()=>{addSigner()}} className="btn btn-secondary mt-2"> + Add a role</button>
        </div>       
                    </div>
                    <div className='col-12 mb-5' >
                    <button  onClick={handleNext} style={{float: 'right',backgroundColor : '#4dd6b6'}}  class="btn btn-success m-1 mt-5 float-end">Next &raquo;</button>
                    </div>
                </div>
            </div>
            <br /><br /><br />
            </div>
        </div>
        </>
    );
}

export default Step2