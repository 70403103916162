// auth.js

import axios from 'axios';

const API_URL = 'https://test.sfaxcharityerpapp.com/api'; // Replace with your API endpoint

class AuthService {
  login(email, password) {
    const queryString = `email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`;
    console.log(queryString);
    return axios
      .post(`${API_URL}/login?${queryString}`,null, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    } )
      .then(response => {
        console.log(response);
        if (response.data.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(username, email, password) {
    return axios.post(`${API_URL}/register`, {
      username,
      email,
      password,
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
}

export default new AuthService();
