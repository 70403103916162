// ProtectedRoute.js
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import AuthService from './auth';

const ProtectedRoute = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();

  useEffect(() => {
    if (!currentUser) {
      // Redirect to the login page if the user is not authenticated
      navigate('/login');
    }
  }, [currentUser, navigate]);

  return (
    <div>
      {/* Render the protected content using Outlet */}
      <Outlet />
    </div>
  );
};

export default ProtectedRoute;
