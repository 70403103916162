import React from 'react'
import { Link } from "react-router-dom"
import Heading from "../../common/heading/Heading"
import "./hero1.css"

export default function Hero1() {
return (
    <>
        <section className='hero'>
            <div className='container'>
                <div className='row'>

 <p></p> <p></p>

                  <Heading subtitle='WELCOME TO E-SIGNATURE' title='More than just electronic signature software' />
                    <p>Improve the Speed and Security of your Documents with <br/>E-Signatures's fluid Electronic Eignature software.</p>
                    <div className='row'>
                           <div className='col-xl-3  col-sm-12'>
                        <button className='primary-btn'>
                            GET STARTED NOW <i className='fa fa-long-arrow-alt-right'></i>
                        </button>
                             </div>
                          <div className='col-xl-3  col-sm-12'>
                        <button>
                            VIEW COURSE <i className='fa fa-long-arrow-alt-right'></i>
                        </button>
                             </div>
                    </div>
                </div>
            </div>
        </section>
        <div className='margin'></div>
    </>
)
}

