import React from "react"
import Back1 from "../common/back/Back1"
import TeamCard1 from "./TeamCard1"
import "./team1.css"
import Awrapper from "../about/Awrapper"
import "../about/about.css"

export default function Team1() {
  return (
    <>
      <Back1 title='Team' />
      <section className='team padding'>
        <div className='container grid'>
          <TeamCard1 />
        </div>
      </section>
      <Awrapper />
    </>
  )
}
