import React from "react"
import AboutCard1 from "../about/AboutCard1"
import Hero1 from "./hero/Hero1"
import Testimonal1 from "./testimonal/Testimonal1"
import './Home.css';

export default function Home1() {
  return (
      <>
      <Hero1 />
      <AboutCard1 />
      <Testimonal1 />
    </>
  )
}