import { useRef, useState, useEffect, useContext } from 'react';
import './LoginPage.css'
//import { Link } from "react-router-dom"
import logo from './pictures/logo.png'
import welcomeimg from './pictures/11.png'
import axios from 'axios';
import AuthContext from "../context/AuthProvider";
import AuthService from '../auth/auth';
function LoginPage() {
  

const { setAuth } = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('');
    const [password, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [email, password])
    const handleSubmit = async (e) => {
        e.preventDefault();

        alert('ok');
    AuthService.login(email, password).then(
        () => {
            alert('ok');
            console.log(localStorage.getItem('user'));
          // Handle successful login, e.g., redirect to a protected route
        },
        error => {
          // Handle login error
        }
      );
    }
    const handleSubmit2 = async (e) => {
        e.preventDefault();
        console.log({ email, password });
        const queryString = `email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`;

        try {
            const response = await axios.post(`https://test.sfaxcharityerpapp.com/api/login?${queryString}`,
                null,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            console.log(JSON.stringify(response?.data));
         console.log(JSON.stringify(response?.data?.data?.token));
         console.log(response?.data?.data?.token);

            const accessToken = response?.data?.data?.token;
            const roles = response?.data?.data?.name;
            setAuth({ email, password, roles, accessToken });
            setEmail('');
            setPwd('');
            setSuccess(true);
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing email or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

return (<>{success ? (
    <section>
        <h1>You are logged in!</h1>
        <br />
        <p>
            <a href="/table">Go to Home</a>
        </p>
    </section>
) :
    <div className='main-login'>
        <div className='login-contain'>
            <div className='left-side'>
                <div className='img-class'>
                    <img src={logo} id='img-id' alt='' srcSet=''/>
                </div>
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <form  onSubmit={handleSubmit}>
                    <label for="email1" htmlFor="username">Email</label>
                        <input  type="text"
                            id="username"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required />
                    <label htmlFor="password">Password</label>
                        <input type="password"
                            id="password"
                            onChange={(e) => setPwd(e.target.value)}
                            value={password}
                            required />
                    <button type='submit' id='sub_butt'>Login</button>
                </form>
                <div className='footer'>
                    <h4><b>Don't have an account ? <a href='/register'> Register Now </a></b></h4>
                </div>
            </div>
            <div className='right-side'>
                <div className='welcomeNote'>
                    <h2><b>Welcome Back!</b></h2>
                </div>
                <div className='welcomeImg'>
                    <img src={welcomeimg} id='wel-img-id' alt='' srcSet=''/>
                </div>
            </div>
        </div>
    </div>
}</>)
}

export default LoginPage;
