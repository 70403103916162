import React from 'react'
import "./about.css"
import Back1 from "../common/back/Back1"
import AboutCard from "./AboutCard1"

export default function About() {
return (
    <>
        <Back1 title='About Us' />
        <AboutCard />
    </>
)
}