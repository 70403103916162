import React from 'react';
import './Modal.css';
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="mymodal">
      <div className="mymodal-overlay" onClick={onClose} />
      <div className="mymodal-content">
        {children}
      </div>
    </div>
  );
};

export default Modal;