import React,{useEffect, useRef,useState,createRef} from 'react';
import { Stepper } from 'react-form-stepper'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Example from './Example.js'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import * as pdfjsLib from 'pdfjs-dist/webpack';

import {  useParams } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';

function Step3() {
    const navigate = useNavigate();
    const inputElement = useRef();
    const [file, setFile] = useState(null);
    const [isSticky, setIsSticky] = useState(false);
    const handleScroll = () => {
        setIsSticky(window.scrollY > 0);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const { id } = useParams();
    const [signers, setSigners] = useState([]);
    const [Title, setTitle] = React.useState("");
    const [pdf, setPdf] = React.useState("");
    const [images, setImages] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pdfRendering, setPdfRendering] = React.useState("");
    const [pageRendering, setPageRendering] = React.useState("");
    const [imagePage, SetImagePage] = React.useState();
    const [page, SetPage] = React.useState(0);
    const [uri, SetUri] = React.useState();
    const [index, SetIndex] = React.useState(0);
  
    const childRef = useRef(null);
    const buttonRef = useRef(null);
    const fieldNameUpdateRef = useRef(null);
    const SignatoriesUpdateRef = useRef(null);
    const fieldTypeUpdateRef = useRef(null);
    const fieldNameRef = useRef(null);  
    const fieldTypeRef = useRef(null);
    const SignatoriesRef = useRef(null);
    const [showUpdateSection, setShowUpdateSection] = useState(false);
    const [valueUpdateSection, setValueUpdateSection] = useState({});
    const fieldObligatoireUpdateRef = useRef(null);
    const fieldObligatoireRef = useRef(null);
   
    useEffect(() => {
      axios.get(`https://test.sfaxcharityerpapp.com/api/getDoc/${id}`, { responseType: 'blob' })
        .then((res) => {
          let test = URL.createObjectURL(res.data);
          console.log(test);
          SetUri(test);
          setPdfRendering(true);
          async function showPdf2(event) {
            var _PDF_DOC = await pdfjsLib.getDocument({ url: event }).promise;
            const page = await _PDF_DOC.getPage(1);
            const viewport = page.getViewport({ scale: 1.5 });
            setPdf(_PDF_DOC);
            setPdfRendering(false);
          }
          showPdf2(test)
        })
        .catch((res) => {  
        });
  
      axios.get(`https://test.sfaxcharityerpapp.com/api/getSigners/${id}`)
      .then((res) => {
        console.log(res.data)
        setSigners(res.data)
      
      })
      .catch((res) => {
    
        
      });
       
    }, []);
    const changePage = (idx) => {
      //alert(idx);
     
      SetImagePage(images[idx])
      SetPage(idx)
    }
    const styles = {
      wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "5px",
        maxHeight: 500,
        
      },
      imageWrapper: {
        // width: "300px",
        // height: "350px",
        border: "1px solid rgba(0,0,0,0.15)",
        borderRadius: "3px",
        boxShadow: "0 2px 5px 0 rgba(0,0,0,0.25)",
        padding: "0"
      }
    };
    const Addsignature = ()=>{
     //alert(SignatoriesRef.current.selectedOptions[0].attributes.color.value)
      childRef.current.addSignature(fieldNameRef.current.value,fieldTypeRef.current.value,fieldObligatoireRef.current.checked,SignatoriesRef.current.value,SignatoriesRef.current.selectedOptions[0].attributes.color.value)
      //console.log(fieldNameRef)closeUpdateSection={closeUpdateSection}
     
  
    }
    const updatesignature = (idx)=>{
      console.log(fieldNameUpdateRef)
      childRef.current.updateSignature(idx,fieldNameUpdateRef.current.value,fieldTypeUpdateRef.current.value,fieldObligatoireUpdateRef.current.checked,SignatoriesUpdateRef.current.value)
      //console.log(fieldNameRef)
      setShowUpdateSection(false)
    }
    const DeleteBox = (idx)=>{
      childRef.current.DeleteBox(idx)
      setShowUpdateSection(false)
    }
    const createPdf = ()=>{
      childRef.current.createPdf(id)
      buttonRef.current.hidden = true;
      //console.log(buttonRef.current.hidden)
    }
    const getUpdateSection = (value,index)=>{
      SetIndex(index);
      console.log(value);
      setValueUpdateSection(value)
      setShowUpdateSection(true)
     
    }
    async function renderPage() {
      setPageRendering(true);
      const imagesList = [];
      const canvas = document.createElement("canvas");
      canvas.setAttribute("className", "canv");
      let canv = document.querySelector(".canv");
  
      for (let i = 1; i <= pdf.numPages; i++) {
        var page = await pdf.getPage(i);
        var viewport = page.getViewport({ scale: 1 });
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        var render_context = {
          canvasContext: canvas.getContext("2d"),
          viewport: viewport
        };
        await page.render(render_context).promise;
        let img = canvas.toDataURL("image/png");
        imagesList.push(img);
      }
      setImages(imagesList);
      SetImagePage(imagesList[0])
      setPageRendering(false);
    }
  
    useEffect(() => {
      pdf && renderPage();
      // eslint-disable-next-line
    }, [pdf, currentPage]);

    return (
    <>
        <div className='main-table'>
            <header className={isSticky ? 'sticky' : ''}>
            <a href="/" className="logo">Home Page</a>
            <ul>
                <li><a href="/table">Templates</a></li>
                <li><a href="#">Signature</a></li>
                <li><a href="#">UserMenu</a></li>
            </ul>
            </header>
            <div className='container mt-5'>
            <br/><br/><br/><br/>
            <div>
                <h1 className="styled-title1">Create</h1>
                <h1 className="styled-title">TEMPLATE...</h1>
            </div>
               
    <div className="container ">
      <div className='row' style={{width: '100%'}}>
        <div className='col-12  mb-5 '>
    <Stepper
      color="#23b561"
      fontSize="15px"
      fontColor="#18aed6"
      steps={[
        { label: "step 1" },
        { label: "step 2" },
        { label: "step 3" },
      ]}
      currentStep={3}
    />
        </div>
        <div className='col-md-2  col-xs-12 '>
        
          <div>
          {showUpdateSection ? 
           <div className="card">
           <div className="card-body">
           <i className="fa fa-times float-end text-danger" style={{cursor: 'pointer'}} onClick={()=>{setShowUpdateSection(false); childRef.current.closeUpdateSection()}}></i>
             <h5 className="card-title">Update field</h5>
             <div className="mb-3">
             
               <label  className="form-label">Signatories</label>
               <select value={valueUpdateSection.role} ref={SignatoriesUpdateRef} className="form-select" onChange={(e) => setValueUpdateSection({
      ...valueUpdateSection, 
      role: e.target.value,
    }) }>
                 {signers.map((value)=>{
                  let mycolor = value.role_color;
                   return (<option style={{
                     backgroundColor:mycolor
                   }} color={value.role_color} value={value.id}>{value.role_name}</option>)
                 })

                 }
                 
               </select>
             </div>
             <div className="mb-3">
               <label htmlFor="exampleInputEmail1" className="form-label">Field name</label>
               <input type="text" className="form-control"  value={valueUpdateSection.title} ref={fieldNameUpdateRef} onChange={(e) => setValueUpdateSection({
      ...valueUpdateSection, 
      title: e.target.value,
    }) } />
             </div>
             <div className="mb-3">
               <label  className="form-label">Field type</label>
               <select value={valueUpdateSection.type} className="form-select" ref={fieldTypeUpdateRef} onChange={(e) => setValueUpdateSection({
      ...valueUpdateSection, 
      type: e.target.value,
    }) }>
                 <option value="signature" >signature</option>
                 <option value="name">name</option>
                 <option value="email">email</option>
               </select>
             </div>
             <div className="mb-3 form-check">
               <input type="checkbox" id='ttest2' checked={valueUpdateSection.Obligatoire} onChange={(e) => setValueUpdateSection({
      ...valueUpdateSection, 
      Obligatoire: e.target.checked,
    }) } className="form-check-input" ref={fieldObligatoireUpdateRef} />
               <label className="form-check-label" htmlFor='ttest2'>Obligatoire</label>
             </div>
             <div className="mb-3">
              <a href={'#'} className={'text-danger'} onClick={()=>DeleteBox(index)}>  <i className="fa fa-trash  text-danger" ></i> Delete this field</a>
              </div>
             <button type="button"  onClick={()=>updatesignature(index)} className="btn btn-success btn-lg mt-5">Save</button>

           </div> 
         </div>
             : 

           
            <div className="card">
            <div className="card-body">
              <h5 className="card-title">Add new field</h5>
              <div className="mb-3">
                <label  className="form-label">Signatories</label>
                <select ref={SignatoriesRef} className="form-select" >
                  {signers.map((value)=>{
                   let mycolor = value.role_color;
                    return (<option style={{
                      backgroundColor:mycolor
                    }} color={mycolor} value={value.id}>{value.role_name}</option>)
                  })

                  }
                  
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Field name</label>
                <input type="email" ref={fieldNameRef} className="form-control"  aria-describedby="emailHelp"/>
              </div>
              <div className="mb-3">
                <label  className="form-label">Field type</label>
                <select  className="form-select" ref={fieldTypeRef}>
                  <option value="signature">signature</option>
                  <option value="name">name</option>
                  <option value="email">email</option>
                </select>
              </div>
              <div className="mb-3 form-check">
                <input type="checkbox" id='ttest' className="form-check-input" value="ture" ref={fieldObligatoireRef} />
                <label className="form-check-label" htmlFor='ttest'>Obligatoire</label>
              </div>
              <button type="button" ref={buttonRef} onClick={()=>Addsignature()} className="btn btn-primary btn-lg mt-5">Add field</button>

            </div> 
          </div>
            }
          </div>
          <p>Double-click to edit or delete a box</p>
          <div>
          
       
          </div>
        </div>
        <div className='col-md-8  col-xs-12' 
            style={{
                overflow: "hidden",
                backgroundColor: '#f8f9fa',
                padding: 0,
            }}>
          
        

            <DndProvider backend={HTML5Backend}>
                <Example title = {Title} ref={childRef} uri={uri} pageIdx={page}  closeSection={()=>{setShowUpdateSection(false);}} getUpdateSection={getUpdateSection} image={imagePage} />
            </DndProvider>
        
  

        </div>
        <div className='col-2' 
            style={{
                overflow: "auto"
            }}>
          
          <div id="pdf-contents">
       
            <div id="image-convas-row" style={{
              overflow:"auto"
            }}>
              {/* <canvas id="pdf-canvas" width={width} height={height}></canvas> */}
              <div style={styles.wrapper} >
                {images.map((image, idx) => (
                  <div key={idx} style={styles.imageWrapper}>
                      
                  <img
                      id="image-generated"
                      src={image}
                      alt="pdfImage"
                      style={{
                        width: "100%",
                        height: "100%",
                        margin: "0",
                        border: "none"
                      }}
                      onClick={()=>changePage(idx)} 
                    /> 
                  </div>
                ))}
              </div>
            </div>
            <div id="page-loader" hidden={!pageRendering}>
              Loading page ...
            </div>
       
        </div>
        <div>
        <div className="d-grid gap-2 col-6 mx-auto">
</div>
        
        </div>

  

        </div>
        <div className='col-12  mb-5 '>
        <a onClick={()=>createPdf(id)} style={{backgroundColor : '#4dd6b6'}} className="btn btn-success m-1 mt-5 float-end">Next &raquo;</a>
        <a href="#" className="btn btn-secondary m-1 mt-5 float-end">&laquo; Previous</a>

        </div>
      </div>
      
    </div>

          
            <br /><br /><br />
            </div>
        </div>
        </>
    );
}

export default Step3